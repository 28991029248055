import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, distinctUntilChanged, BehaviorSubject, skip, combineLatest } from 'rxjs';
import { notificationsRepository } from 'src/app/core/stores/notifications.repository';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-notifications-filters',
    standalone: true,
    imports: [
        AsyncPipe,
        SharedModule
    ],
    template: `
        <div class="notifications-filters-cont">
            <div class="content">
                <div class="title">{{ 'NOTIFICATIONS_VIEW.YOUR_NOTIFICATIONS' | translate }}</div>
                @if (vm$ | async; as vm) {
                    <div class="filter-options">
                        <button
                            evc-button
                            class="notifications-filter-btn mr-32"
                            [toggled]="vm.activeOnly"
                            (click)="repo.updateActiveOnly(!vm.activeOnly)"
                        >{{ 'NOTIFICATIONS_VIEW.ACTIVATED_ONLY' | translate }}</button>
                        <input
                            type="text"
                            class="notifications-search-input"
                            [placeholder]="'COMMON.SEARCH' | translate"
                            [value]="vm.search"
                            (input)="handleSearch($event)"
                        >
                        <button
                            evc-icon-button
                            btnSize="sm"
                            [icon]="vm.search ? 'clear' : 'search'"
                            (click)="searchQuery$.next('')"
                        ></button>
                    </div>
                }
            </div>
        </div>
    `,
    styleUrl: './notifications-filters.component.scss'
})
export class NotificationsFiltersComponent {
    public searchQuery$ = new BehaviorSubject<string>('');
    public vm$ = combineLatest({
        activeOnly: this.repo.activeOnly$,
        search: this.repo.search$
    })

    constructor(
        public repo: notificationsRepository,
    ) {
        // throttle search input
        this.searchQuery$.pipe(
            takeUntilDestroyed(),
            skip(1),
            debounceTime(150),
            distinctUntilChanged(),
        ).subscribe((value) => {
            this.repo.updateSearch(value)
        });
    }

    handleSearch(event: Event) {
        let value = (event.target as HTMLInputElement).value;
        this.searchQuery$.next(value);
    }
}
