import { Component } from '@angular/core';
import { PermissionsService } from '../core/app-services/permissions.service';
import { map, Observable } from 'rxjs';
@Component({
    selector: 'app-not-found',
    template: `
    <div class="container pt-32">
        <div class="box">
            <h1>404</h1>
            <p>{{ 'NOT_FOUND_VIEW.INFO' | translate }}</p> <br>
            @if (returnLink$ | async; as returnLink) {
                <button 
                    evc-button
                    btnSize="lg"
                    variant="colored"
                    [routerLink]="returnLink.link"
                >{{ returnLink.title | translate }}</button>
            }
        </div>
    </div>
    `,
    styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
    public returnLink$: Observable<{
        title: string,
        link: string[]
    }>;

    constructor(
        private _permService: PermissionsService
    ) {
        this.returnLink$ = this._permService.userPermissions$.pipe(
            map((permissions) => {
                if (this._permService.hasPermission('routes.dashboard')) {
                    return {
                        title: 'NOT_FOUND_VIEW.BUTTON.DASHBOARD',
                        link: ['/overview']
                    }
                } else {
                    return {
                        title: 'NOT_FOUND_VIEW.BUTTON.MANAGE_USERS',
                        link: ['/admin-users']
                    }
                }
            })
        )
    }
}
