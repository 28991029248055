import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppUpdateService } from '../core/app-services/app-update.service';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../core/app-services/global.service';
import { detailsRepository, getDefaultRange } from '../core/stores/details.repository';

@Component({
    selector: 'evc-private-modals',
    template: `
        <!-- A P P L Y  U P D A T E S -->
        <app-modal [(open)]="appUpdateService.updateAppModalOpen">
            <ng-container header>
                <p class="subheadline" translate [translateParams]="{brandName: environment.brandName}">UPDATE_INFO.TITLE</p>
            </ng-container>
            <ng-container body>
                <div class="flex-column w-100 align-items-center justify-content-between">
                    <p class="copy text-larger text-left pb-16" [innerHTML]="'UPDATE_INFO.MODAL_TEXT' | translate: {brandName: environment.brandName}"></p>

                    <div class="flex-column w-100 align-items-center justify-content-center pt-32">
                        <button 
                            id="update-app"
                            evc-button
                            btnSize="lg"
                            variant="colored"
                            icon="browser_updated"
                            (click)="appUpdateService.applyUpdate()"
                        >{{ 'UPDATE_INFO.APPLY_UPDATE' | translate }}</button>

                        <a 
                            evc-button
                            variant="flat"
                            class="mt-8"
                            (click)="appUpdateService.updateAppModalOpen.set(false)"
                        >{{ 'UPDATE_INFO.REFRESH_LATER' | translate }}</a>
                    </div>
                </div>
            </ng-container>
        </app-modal>

        <!-- Date Range warning -->
        <app-modal [(open)]="globalService.dateRangeWarningOpen">
            <ng-container header>
                <p class="subheadline">{{ 'LONG_INTERVAL.TITLE' | translate }}</p>
            </ng-container>
            <ng-container body>
                <div class="flex-column w-100 align-items-center justify-content-between">
                    <p 
                        class="copy text-larger text-center"
                        [innerHTML]="'LONG_INTERVAL.MODAL_TEXT' | translate"
                    ></p>

                    <br><br>

                    <div class="flex-column w-100 align-items-center justify-content-center pt-32">
                        <button 
                            evc-button
                            btnSize="lg"
                            variant="colored"
                            icon="restore"
                            (click)="resetDateRange()"
                        >{{ 'LONG_INTERVAL.RESET_TO_DEFAULT' | translate }}</button>
                        <a 
                            evc-button
                            variant="flat"
                            class="mt-8" 
                            (click)="dontShowAgain()"
                        >{{ 'LONG_INTERVAL.KEEP_SELECTION' | translate }}</a>
                    </div>
                </div>
                
            </ng-container>
        </app-modal>
    `,
    styleUrl: './private-modals.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivateModalsComponent {
    // This component is used to display private modals
    // only visible to user once he's authenticated
    readonly environment = environment;

    constructor(
        public appUpdateService: AppUpdateService,
        public globalService: GlobalService,
        private _detailsRepo: detailsRepository
    ) {}

    resetDateRange() {
        this._detailsRepo.setDateRange(getDefaultRange())
        this.globalService.toggleDateRangeWarning()
    }

    dontShowAgain() {
        this.globalService.dontShowRangeWarningAgain()
        this.globalService.toggleDateRangeWarning()
    }
}
