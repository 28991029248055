import { Component, computed, inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, catchError, combineLatest, filter, from, map, mergeMap, share, startWith, switchMap, take, tap, toArray } from 'rxjs';
import { NotificationService } from 'src/app/core/app-services';
import { DashboardService, FilterSetService, UserService } from 'src/app/core/data-backend/data-services';
import { Alert, FilterSet, SharedFilterSet } from 'src/app/core/data-backend/models';
import { ColumnService } from 'src/app/core/helpers/column.service';
import { decodeString } from 'src/app/core/helpers/utils.helper';
import { ChargerKeyName } from 'src/app/core/pipes';
import { overviewRepository } from 'src/app/core/stores/overview.repository';
import { stationFiltersRepository } from 'src/app/core/stores/station-filters.repository';
import { TableCellActionEvent, TableColumn } from 'src/app/shared/table/table.component';

type AlertRow = {
    numOfStations: null | number,
    filterSet: string,
    filters: string[],
    condition: string
}

type FilterSetWithName = (FilterSet | SharedFilterSet) & { ownerName?: string };

@Component({
    selector: 'evc-overview-alerts',
    template: `
        <evc-collapsible
            [loading]="(loadingState$ | async) == 'loading'"
            [showChevron]="false"
            [collapsed]="(collapsed$ | async) ?? true"
            (collapsedChange)="collapsed$.next($event)"
        >
            <ng-container header>
                <div 
                    class="flex-column align-items-center justify-content-center alert-row"
                    [tooltip]="'DASHBOARD.ALERTS.INFO' | translate"
                    [size]="'small'"
                    [textAlign]="'left'"
                    [toSide]="'bottom'"
                >
                    <div class="flex-row align-items-center justify-content-center">
                        <span class="material-icon alert-icon">notifications</span> <span class="counter">{{ (rows$ | async)?.length ?? ' - ' }}</span>
                    </div>
                    <p class="alert-disclaimer">
                        @if ((rows$ | async)?.length !== 1) {
                            {{ 'DASHBOARD.ALERTS.ALERT_IN_FILTER_SET.OTHER' | translate }}
                        } @else {
                            {{ 'DASHBOARD.ALERTS.ALERT_IN_FILTER_SET.ONE' | translate }}
                        }
                    </p>
                </div>
            </ng-container>
            <ng-container body>
                <evc-table
                    [columns]="columns()"
                    [rows]="rows$ | async"
                    [resizeable]="false"
                    [state]="loadingState$ | async"
                    [boxShadow]="true"
                    (onCellAction)="handleCellAction($event)"
                >
                </evc-table>
                <div class="flex-column align-items-center justify-content-center pb-8">
                    <div class="p-8 mt-8">
                        <a 
                            evc-button
                            variant="flat"
                            [routerLink]="'/filtersets'" 
                        >{{ 'DASHBOARD.ALERTS.MANAGE_FILTER_SETS' | translate }}</a>
                    </div>
                    <button 
                        class="close flex-row align-items-center justify-content-center"
                        (click)="collapsed$.next(true)"
                    >
                        <span class="material-icon">expand_less</span>
                        {{ 'COMMON.CLOSE' | translate }}
                    </button>
                </div>
            </ng-container>
        </evc-collapsible>
    `,
    styleUrls: ['./overview-alerts.component.scss']
})
export class OverviewAlertsComponent {
    public loadingState$: Observable<'loading' | 'success' | 'error'>;
    public collapsed$ = new BehaviorSubject<boolean>(true);
    public alerts$: Observable<Alert[]>;
    private _alertFilterSets$: Observable<{
        filterSet: FilterSetWithName;
        alert: Alert;
    }[]>;
    public rows$: Observable<AlertRow[]>;

    public columns: Signal<TableColumn[]>;

    private _chargerKeyName: ChargerKeyName = inject(ChargerKeyName);
    private _notificationService: NotificationService = inject(NotificationService);

    constructor(
        private _overviewRepo: overviewRepository,
        private _filterSetService: FilterSetService,
        private _dashboardService: DashboardService,
        private _userService: UserService,
        private _filtersRepo: stationFiltersRepository,
        private _columnService: ColumnService,
        private _translate: TranslateService
    ) {
        this.alerts$ = this._filtersRepo.alerts$.pipe(
            map(({data}) => data.filter((alert) => alert.triggered))
        )

        this.loadingState$ = combineLatest([
            this._filtersRepo.filterSets$.pipe(map(({status}) => status)),
            this._filtersRepo.alerts$.pipe(map(({status}) => status))
        ]).pipe(
            map((states) => {
                return states.includes('loading') 
                    ? 'loading'
                    : states.includes('error')
                        ? 'error'
                        : 'success';
            })
        )

        // map filterSet obj to triggered alert
        this._alertFilterSets$ = this.alerts$.pipe(
            switchMap((triggeredAlerts) => {
                // get all filterSets of triggered alerts
                return from(triggeredAlerts).pipe(
                    mergeMap((alert) => {
                        // get matching filterSet
                        return this._filterSetService.getFilterSet({ filtersetid: alert.filterSetId }).pipe(
                            filter((filterSet) => {
                                if ('isSubscribed' in filterSet) {
                                    return !(filterSet.isShared && !filterSet.isSubscribed);
                                }
                                return true;
                            }),
                            mergeMap((filterSet) => {
                                let ownerId = filterSet.ownerId;
                                return this._userService.getUsersByUuid({uuid: [ownerId]}).pipe(
                                    map((user) => {
                                        let filterSetWithName: FilterSetWithName = {...filterSet};
                                        if (user.length == 0) filterSetWithName.ownerName = 'undefined';
                                        else filterSetWithName.ownerName = `${user[0].firstName} ${user[0].lastName}`;
                                        return { filterSet: filterSetWithName, alert };
                                    })
                                );
                            }),
                        );
                    }),
                    toArray()
                );
            }),
            share({ connector: () => new ReplaySubject(1) })
        );

        // gets num of stations matching this filterset
        const filterSetResults$ = this._alertFilterSets$.pipe(
            switchMap((filterSets) => {
                return from(filterSets).pipe(
                    take(filterSets.length),
                    mergeMap(({ filterSet }) => {
                        // request stations with current set
                        let filterQuery = filterSet.filterSetDefinition.map((filter) => {
                            let filterObj: any = {};
                            filterObj[filter.id!] = filter.value!;
                            return filterObj
                        });
                        return this._dashboardService.getLocationsOfChargingStations({
                            // @ts-ignore
                            filters: filterQuery
                        }).pipe(
                            catchError(() => EMPTY),
                            map((locations) => {
                                return {
                                    filtersetId: filterSet.filterSetId,
                                    results: locations.length
                                }
                            })
                        )
                    }),
                    toArray()
                )
            }),
            // start with empty to allow values in table before mergeMap above finishes
            startWith([])
        )

        this.rows$ = combineLatest({
            alertFilterSets: this._alertFilterSets$,
            filterSetResults: filterSetResults$,
            langChanged: this._translate.onLangChange.pipe(startWith(null))
        }).pipe(
            map(({alertFilterSets, filterSetResults}) => {
                const operatorMap = {
                    gt: this._t('ALERTS.MORE_THAN'),
                    eq: this._t('ALERTS.EQUALS'),
                    lt: this._t('ALERTS.LESS_THAN')
                }
                // assign alert / filterset data
                return alertFilterSets.map(({filterSet, alert}) => {
                    const condition = alert.operator ? `${operatorMap[alert.operator]} ${alert.value}` : ' - ';
                    const filtersInSet  = filterSet.filterSetDefinition.map((def) => this._chargerKeyName.transform(def.id ?? ''));
                    const filterSetResult = filterSetResults.find((res) => res.filtersetId === filterSet.filterSetId)?.results;

                    return {
                        numOfStations: filterSetResult ?? null,
                        filterSet: filterSet.name ?? filterSet.filterSetId.toString(),
                        filters: filtersInSet,
                        ownerName: filterSet.ownerName,
                        ownerId: filterSet.ownerId,
                        condition
                    }
                })
            })
        );

        const newLang = toSignal(this._translate.onLangChange);
        this.columns = computed(() => {
            const lang = newLang();

            return [
                {
                    id: 0,
                    title: this._t('FILTERS.FILTER_SET.ONE'),
                    keys: [
                        {
                            key: 'numOfStations',
                            title: this._t('FILTER_SETS_VIEW.COLUMNS.RESULTS'),
                            type: 'number'
                        },
                        {
                            key: 'filterSet',
                            title: this._t('FILTERS.FILTER_SET.ONE'),
                            type: 'string'
                        }
                    ],
                    config: {
                        noFilter: true,
                        noSearch: true,
                        noMinWidth: true,
                        squished: 'left',
                        defaultWidth: 300
                    },
                    actions: [
                        {
                            title: this._t('DASHBOARD.ALERTS.APPLY_FILTER_SET'),
                            id: 'apply-filter-set',
                            renderAction(attr) {
                                const [numOfStations, filterSet] = attr;
                                
                                return `
                                    <div class="num-circle ${numOfStations == null ? 'loading' : ''}">
                                        <span>${numOfStations ?? ''}</span>
                                    </div>
                                `
                            },
                        }
                    ],
                    renderCell(attr) {
                        const [numOfStations, filterSet] = attr;
        
                        return `<p class="pl-8 font-weight-500">${filterSet}</p>`;
                    },
                },
                {
                    id: 1,
                    title: this._t('FILTER_SETS_VIEW.COLUMNS.CREATOR'),
                    keys: [
                        {
                            key: 'ownerName',
                            title: this._t('FILTER_SETS_VIEW.COLUMNS.CREATOR'),
                            type: 'string'
                        }, {
                            key: 'ownerId',
                            title: 'Owner Id',
                            type: 'string',
                            hidden: true
                        }
                    ],
                    config: {
                        noFilter: true,
                        noSearch: true,
                        noSort: true,
                        noMinWidth: true,
                        defaultWidth: 150
                    },
                    renderCell: (attr: any[]) => {
                        return this._columnService.getEmailStyling(attr);
                    }
                },
                {
                    id: 2,
                    title: this._t('FILTER_SETS_VIEW.COLUMNS.CONDITION'),
                    keys: [
                        {
                            key: 'condition',
                            title: this._t('FILTER_SETS_VIEW.COLUMNS.CONDITION'),
                            type: 'string'
                        }
                    ],
                    config: {
                        noFilter: true,
                        noSearch: true,
                        noSort: true,
                        noMinWidth: true,
                        defaultWidth: 150
                    }
                },
                {
                    id: 3,
                    title: this._t('FILTER_SETS_VIEW.COLUMNS.FILTERS'),
                    keys: [
                        {
                            key: 'filters',
                            title: this._t('FILTER_SETS_VIEW.COLUMNS.FILTERS'),
                            type: 'array'
                        }
                    ],
                    config: {
                        noFilter: true,
                        noSearch: true,
                        squished: 'left'
                    },
                    renderCell(attr) {
                        return `<span class="subline">${attr[0].join(',&nbsp;')}</span>`
                    }
                },
                {
                    id: 4,
                    title: '',
                    keys: [
                        {
                            key: 'filters',
                            title: this._t('FILTER_SETS_VIEW.COLUMNS.FILTERS'),
                            type: 'array',
                            hidden: true
                        }
                    ],
                    actions: [
                        {
                            title: this._t('DASHBOARD.ALERTS.APPLY_FILTER_SET'),
                            id: 'apply-filter-set',
                            icon: 'filter_alt',
                            condition: (attr: any[]) => {
                                const [ filters ] = attr;
                                return filters && filters.length > 0
                            }
                        }
                    ],
                    renderCell: () => '',
                    config: {
                        noFilter: true,
                        noSearch: true,
                        noSort: true,
                        noMinWidth: true,
                        defaultWidth: 80
                    }
                }
            ]
        })
    }

    private _t(path: string, interpolateParams?: Object): string {
        return this._translate.instant(path, interpolateParams)
    }

    public handleCellAction(event: TableCellActionEvent) {
        if (event.actionId == 'apply-filter-set') {
            this._alertFilterSets$.pipe(
                take(1),
                tap((rows) => {
                    const filterSet = rows[event.row as number].filterSet;
                    const filterName = decodeString(filterSet.name);
                    this._filtersRepo.applyFilterSet(filterSet);
                    this._notificationService.showSuccess(this._t('DASHBOARD.ALERTS.FILTER_SET_APPLIED', {content: filterName}));
                })
            ).subscribe()
        }
    }
}
