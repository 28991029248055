import { ChangeDetectionStrategy, Component, computed, inject, input, model, output, signal } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "evc-disable-restarts",
    template: `
        <div class="disable-restarts-dialog">
            @if (restartDisabledIds().length > 0) {
                <strong>{{ 'DASHBOARD.BULK_ACTIONS.RESTART_STATIONS.DISABLED_RESTARTS_TITLE' | translate }}</strong>
                <span>{{ 'DASHBOARD.BULK_ACTIONS.RESTART_STATIONS.DISABLED_RESTARTS_INFO' | translate: {n: restartDisabledIds().length, m: selectedIds().length} }}</span>
            }
            <evc-textarea
                [(value)]="reason"
                [label]="('DASHBOARD.BULK_ACTIONS.DISABLE_RESTARTS.DIALOG.LABEL' | translate) + ':'"
                [placeholder]="'DASHBOARD.BULK_ACTIONS.DISABLE_RESTARTS.DIALOG.PLACEHOLDER' | translate"
            />
            <div>
                <button
                    evc-button
                    icon="sync_lock"
                    variant="colored"
                    [loading]="loading()"
                    [disabled]="loading() || selectedIds().length === restartDisabledIds().length"
                    [tooltip]="tooltip()"
                    size="small"
                    (click)="submit.emit({ reason })"
                >{{ 'DASHBOARD.BULK_ACTIONS.DISABLE_RESTARTS.DIALOG.BUTTON' | translate }}</button>
            </div>
        </div>
    `,
    styleUrls: ["./disable-restarts.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisableRestartsComponent {
    private readonly _t = inject(TranslateService);

    public readonly selectedIds = input<string[]>([]);
    public readonly restartDisabledIds = input<string[]>([]);
    public readonly loading = input<boolean>(false);
    public readonly tooltip = computed(() => {
        if (this.restartDisabledIds().length === 0) return null;
        if (this.selectedIds().length === this.restartDisabledIds().length) return this._t.instant("DASHBOARD.BULK_ACTIONS.DISABLE_RESTARTS.DIALOG.TOOLTIP_ALL");
        return this._t.instant("DASHBOARD.BULK_ACTIONS.DISABLE_RESTARTS.DIALOG.TOOLTIP");
    });
    public reason = "";
    public readonly submit = output<{reason: string}>();
}
